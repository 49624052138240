import * as ConfigurationService from '../services/ConfigurationService'

export const PERFORM_CALCULATION_REQUEST = 'PERFORM_CALCULATION_REQUEST';
export const PERFORM_CALCULATION_SUCCESS = 'PERFORM_CALCULATION_SUCCESS';
export const PERFORM_CALCULATION_FAILURE = 'PERFORM_CALCULATION_FAILURE';

export function performCalculationRequest() {
    return {
        type: PERFORM_CALCULATION_REQUEST
    }
}

export function performCalculationSuccess(payload) {
    return {
        type: PERFORM_CALCULATION_SUCCESS,
        payload
    }
}

export function performCalculationFailure(payload) {
    return {
        type: PERFORM_CALCULATION_FAILURE,
        payload
    }
}

export function performCalculation(mode, payload) {
    return (dispatch) => {
        dispatch(performCalculationRequest());

        ConfigurationService.performCalculation(mode, payload)
            .then(response => {
                dispatch(performCalculationSuccess(response));
            })
            .catch(error => {
                dispatch(performCalculationFailure(error));
            });
    }
}
