import * as ActionTypes from '../actions'
import HashSum from "hash-sum";
import CloneDeep from 'clone-deep';
import { v4 as uuidv4 } from 'uuid';

const initialConfiguration = {
    meta: {
        name: "Neue Konfiguration",
        crdate: false,
        guid: false,
        configurationNumber: false,
        pin: false,
        resultConfigurationHash: false
    },
    configurationMode: 'room',
    selectedFilters: {
        color: 'aluminium',
        dimmable: 'on/off',
        lightColor: '840',
        radiationAngle: '90',
        powerLevel: 2,
        lightDistributionPercentage: '50',
        userCommitted: false
    },
    selectedRoom: {
        width: 6,
        length: 12,
        height: 4,
        lightPosition: 3,
        pendulumType: 'rope',
        desiredLux: 300,
        maintenance: '0,8',
        workplaneHeight: 0,
        userCommitted: false
    },
    selectedLine: {
        length: 12,
        lineCount: 1,
        pendulumType: 'none',
        pendulumForceArticle: '982580.010',
        userCommitted: false
    },
    roomLayouts: false,
    selectedRoomLayout: {
        orientation: 'horizontal',
        lineCount: false,
        lineLuminairesCount: false,
    },
    result: false
};

const initialState = {
    filters: false,
    isLoading: false,
    configurations: [],
    currentConfiguration: initialConfiguration,
    registration: false
};

// @ToDo: Refactor reducer to use react redux best practices and improve performance
// Using DeepClone is considered bad practice because it tends to be resource heavy
// and strives against react / redux best practices
// https://www.freecodecamp.org/news/handling-state-in-react-four-immutable-approaches-to-consider-d1f5c00249d5/
// A good first start would probably be moving currentConfiguration into a separate reducer
// to keep objects as flat as possible


const Configuration = (state = initialState, action) => {
    let newState = null;
    switch (action.type) {

        case ActionTypes.FETCH_FILTERS_SUCCESS:
            return {...state, filters: action.payload.data.data.filters};


        case ActionTypes.UPDATE_SELECTED_FILTERS:
            const selectedFilters = {...state.currentConfiguration.selectedFilters};
            selectedFilters[action.payload.key] = action.payload.value;

            return {...state, currentConfiguration: {...state.currentConfiguration, selectedFilters: selectedFilters}};

        case ActionTypes.UPDATE_SELECTED_LINE:
            const selectedLine = {...state.currentConfiguration.selectedLine};
            selectedLine[action.payload.key] = action.payload.value;

            return {...state, currentConfiguration: {...state.currentConfiguration, selectedLine: selectedLine}};

        case ActionTypes.UPDATE_SELECTED_ROOM:
            const selectedRoom = {...state.currentConfiguration.selectedRoom};
            selectedRoom[action.payload.key] = action.payload.value;

            if (action.payload.key === 'height') {
                selectedRoom['lightPosition'] = Math.round((action.payload.value - 1) * 10) / 10;
            }

            return {...state, currentConfiguration: {...state.currentConfiguration, selectedRoom: selectedRoom}};


        case ActionTypes.UPDATE_ROOM_LAYOUT:
            return {...state, currentConfiguration: {...state.currentConfiguration, selectedRoomLayout: action.payload}};


        case ActionTypes.AUTOLAYOUT_ROOM_REQUEST:
            return {...state, isLoading: true};

        case ActionTypes.AUTOLAYOUT_ROOM_FAILURE:
            return {...state, isLoading: false};

        case ActionTypes.AUTOLAYOUT_ROOM_SUCCESS:
            return {...state, currentConfiguration: {...state.currentConfiguration, roomLayouts: action.payload.data.data}, isLoading: false};


        case ActionTypes.PERFORM_CALCULATION_REQUEST:
            return {...state, isLoading: true};

        case ActionTypes.PERFORM_CALCULATION_FAILURE:
            return {...state, isLoading: false};


        case ActionTypes.PERFORM_CALCULATION_SUCCESS:
            newState = {...state, currentConfiguration: {...state.currentConfiguration, result: action.payload.data.result}, isLoading: false};
            newState.currentConfiguration.meta.resultConfigurationHash = HashSum({ selectedRoom: newState.currentConfiguration.selectedRoom, selectedFilters: newState.currentConfiguration.selectedFilters, selectedRoomLayout: newState.currentConfiguration.selectedRoomLayout, selectedLine: newState.currentConfiguration.selectedLine, configurationMode: newState.currentConfiguration.configurationMode });

            if (action.payload.data.meta) {
                newState.currentConfiguration.meta.guid = action.payload.data.meta.guid;
                newState.currentConfiguration.meta.configurationNumber = action.payload.data.meta.configurationNumber;
                newState.currentConfiguration.meta.pin = action.payload.data.meta.pin;
            }

            return newState;



        case ActionTypes.SAVE_CONFIGURATION_REQUEST:

            newState = CloneDeep(state);
            let found = false;

            if (newState.currentConfiguration.meta.crdate === false) {
                newState.currentConfiguration.meta.crdate = new Date();
            }

            if (newState.currentConfiguration.meta.guid) {

                Object.entries(newState.configurations).forEach(([key, config]) => {
                    if (config.meta.guid === newState.currentConfiguration.meta.guid) {
                        found = key;
                    }
                });

            }

            if (found) {
                newState.configurations[found] = newState.currentConfiguration;
            }
            else {
                newState.configurations.push(newState.currentConfiguration);
            }

            newState.currentConfiguration = {...initialConfiguration};

            return newState;

        case ActionTypes.CHANGE_CONFIGURATION_MODE:
            newState = CloneDeep(state);
            newState.currentConfiguration = {...initialConfiguration};
            newState.currentConfiguration.configurationMode = action.payload;
            return newState;

        case ActionTypes.RESET_CONFIGURATION:
            newState = CloneDeep(state);
            newState.currentConfiguration = {...initialConfiguration};
            return newState;

        case ActionTypes.LOAD_CONFIGURATION:
            newState = CloneDeep(state);
            Object.entries(newState.configurations).forEach(([key, config]) => {
                if (config.meta.guid === action.payload) {
                    newState.currentConfiguration = config;
                }
            });
            return newState;

        case ActionTypes.DELETE_CONFIGURATION:
            newState = CloneDeep(state);
            newState.configurations = newState.configurations.filter(item => item.meta.guid !== action.payload);
            return newState;

        case ActionTypes.UPDATE_CONFIGURATION_NAME:
            newState = CloneDeep(state);
            newState.currentConfiguration.meta.name = action.payload;
            return newState;

        case ActionTypes.NEW_CONFIGURATION_REQUEST:
            return {...state, isLoading: true};

        case ActionTypes.NEW_CONFIGURATION_FAILURE:
            return {...state, isLoading: false};

        case ActionTypes.PERFORM_REGISTRATION_REQUEST:
            return {...state, isLoading: true};

        case ActionTypes.PERFORM_REGISTRATION_SUCCESS:
            return {...state, registration: action.payload, isLoading: false};

        case ActionTypes.PERFORM_REGISTRATION_FAILURE:
            return {...state, isLoading: false};
            
        case ActionTypes.GENERATE_UUID:
            newState = CloneDeep(state);
            if ( ! newState.registration) {
                newState.registration = {
                    uid: uuidv4()
                };
            }
            return newState;

        default:
            return state;
    }
};

export default Configuration;
