import styles from './App.module.css';
import { Router } from "@reach/router";

import Home from './screens/Home';
import ConfigurationArticleList from './screens/ConfigurationArticleList';
import ConfigurationEdit from './screens/ConfigurationEdit';
import ConfigurationResult from './screens/ConfigurationResult';
import LampColorEdit from './screens/LampColorEdit';
import LampDimmEdit from './screens/LampDimmEdit';
import LampLightColorEdit from './screens/LampLightColorEdit';
import Registration from './screens/Registration';
import RoomDimensionsEdit from './screens/RoomDimensionsEdit';
import RoomLayoutEdit from './screens/RoomLayoutEdit';
import RoomLightEdit from './screens/RoomLightEdit';
import LineEdit from './screens/LineEdit';

import LocalizedView from './LocalizedView';

function App() {

    return (
        <div className={styles.app}>
            <LocalizedView>
                <Router>
                    <Home path="/" />
                    <ConfigurationEdit path="/edit" />
                    <RoomDimensionsEdit path="/edit/room/dimensions" />
                    <RoomLightEdit path="/edit/room/light" />
                    <RoomLayoutEdit path="/edit/room/layout/:preventReload" />
                    <LineEdit path="/edit/line" />
                    <LampColorEdit path="/edit/lamp/color" />
                    <LampLightColorEdit path="/edit/lamp/lightcolor" />
                    <LampDimmEdit path="/edit/lamp/dimm" />
                    <ConfigurationResult path="/result" />
                    <ConfigurationArticleList path="/result/articlelist" />
                    <Registration path="/registration" />
                </Router>
            </LocalizedView>
        </div>
    );
}

export default App;
