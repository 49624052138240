import {applyMiddleware, createStore, compose} from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage'

import * as ActionTypes from '../actions'
import rootReducer from '../reducers'

const persistConfig = {
    key: "react-web-linedo-app",
    storage,
    stateReconciler: autoMergeLevel2,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)
));
const persistor = persistStore(store);

const getPersistor = () => persistor;
const getStore = () => {
    store.dispatch(ActionTypes.fetchFilters());
    store.dispatch(ActionTypes.generateUUID());
    return store;
};
const getState = () => {
    return store.getState();
};

export { getStore, getState, getPersistor };
