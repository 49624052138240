import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import appStyles from '../App.module.css';

import * as ActionTypes from "../../actions";
import Button from '../../components/Button';
import ButtonSecondary from '../../components/ButtonSecondary';
import ButtonSelect from '../../components/ButtonSelect';
import FilterSelectionButton from '../../components/FilterSelectionButton';
import Heading from '../../components/Heading';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        name: state.currentConfiguration.meta.name,
        configurationMode: state.currentConfiguration.configurationMode,
        selectedRoom: state.currentConfiguration.selectedRoom,
        selectedLine: state.currentConfiguration.selectedLine,
        selectedFilters: state.currentConfiguration.selectedFilters
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetConfiguration: () => dispatch(ActionTypes.resetConfiguration()),
        changeConfigurationMode: (params) => dispatch(ActionTypes.changeConfigurationMode(params))
    };
};

class ConfigurationEdit extends Component {
    
    componentDidUpdate(prevProps) {
        if (prevProps.isFocused !== this.props.isFocused) {
            this.setState(this.state);
        }
    }
    
    handleModeChange = (value) => event => {
        this.props.changeConfigurationMode(value);
    }
    
    render() {
        const {selectedRoom, selectedLine, selectedFilters, configurationMode} = this.props;

        const iconCheck = require('../../assets/icon-check.png');
        const iconPlus = require('../../assets/icon-plus.png');
        
        let enableConfiguration = false;
        if (configurationMode === 'room') {
            if (selectedRoom.userCommitted && selectedFilters.userCommitted) {
                enableConfiguration = true;
            }
        } else if (configurationMode === 'line') {
            if (selectedLine.userCommitted && selectedFilters.userCommitted) {
                enableConfiguration = true;
            }
        }
        
        return (
            <Screen>
                <Heading>{i18n.t('newConfiguration')}</Heading>
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px'}}>
                    <div>
                        <div>{i18n.t('configurationMode')}</div>
                    </div>
                    <div>
                        <ButtonSelect selected={configurationMode === 'room'} title={i18n.t('configurationModes.room')} onClick={this.handleModeChange('room')} />
                        <ButtonSelect selected={configurationMode === 'line'} title={i18n.t('configurationModes.line')} onClick={this.handleModeChange('line')}  />
                    </div>
                </div>
                
                <div className={appStyles.appMain}>
                    {configurationMode === 'room' ? (
                        <FilterSelectionButton image={selectedRoom.userCommitted ? iconCheck : iconPlus} selected={ ! selectedRoom.userCommitted} title={i18n.t('editRoom')} onClick={() => navigate('/edit/room/dimensions')} />
                    ) : (
                        <FilterSelectionButton image={selectedLine.userCommitted ? iconCheck : iconPlus} selected={ ! selectedLine.userCommitted} title={i18n.t('editLine')} onClick={() => navigate('/edit/line')} />
                    )}
                    <FilterSelectionButton image={selectedFilters.userCommitted ? iconCheck : iconPlus} selected={ ! selectedFilters.userCommitted} title={i18n.t('editLamp')} onClick={() => navigate('/edit/lamp/color')} />
                </div>
                
                <Button title={i18n.t('calculate')} onClick={() => navigate('/result')} disabled={!enableConfiguration} />
                <ButtonSecondary title={i18n.t('reset')} onClick={this.props.resetConfiguration} disabled={!(selectedRoom.userCommitted || selectedFilters.userCommitted || selectedLine.userCommitted)} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationEdit);
