import * as ConfigurationService from '../services/ConfigurationService'

export const AUTOLAYOUT_ROOM_REQUEST = 'AUTOLAYOUT_ROOM_REQUEST';
export const AUTOLAYOUT_ROOM_SUCCESS = 'AUTOLAYOUT_ROOM_SUCCESS';
export const AUTOLAYOUT_ROOM_FAILURE = 'AUTOLAYOUT_ROOM_FAILURE';

export function autolayoutRoomRequest() {
    return {
        type: AUTOLAYOUT_ROOM_REQUEST
    }
}

export function autolayoutRoomSuccess(payload) {
    return {
        type: AUTOLAYOUT_ROOM_SUCCESS,
        payload
    }
}

export function autolayoutRoomFailure(payload) {
    return {
        type: AUTOLAYOUT_ROOM_FAILURE,
        payload
    }
}

export function autolayoutRoom(payload) {
    return (dispatch) => {
        dispatch(autolayoutRoomRequest());

        ConfigurationService.autolayoutRoom(payload)
            .then(response => {
                dispatch(autolayoutRoomSuccess(response));
            })
            .catch(error => {
                dispatch(autolayoutRoomFailure(error));
            });
    }
}
