import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';
import mergeClassNames from 'merge-class-names';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faSync } from '@fortawesome/pro-light-svg-icons';

import styles from './RoomLayoutEdit.module.css';
import appStyles from '../App.module.css';

import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import Heading from '../../components/Heading';
import Loading from '../../components/Loading';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        selectedRoom: state.currentConfiguration.selectedRoom,
        roomLayouts: state.currentConfiguration.roomLayouts,
        selectedRoomLayout: state.currentConfiguration.selectedRoomLayout,
        isLoading: state.isLoading
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        autolayoutRoom: (params) => dispatch(ActionTypes.autolayoutRoom(params)),
        updateSelectedRoom: (params) => dispatch(ActionTypes.updateSelectedRoom(params)),
        updateRoomLayout: (params) => dispatch(ActionTypes.updateRoomLayout(params))
    };
};

class RoomLayoutEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orientation: false,
            lineCount: 0,
            lineLuminairesCount: 0,
            error: false
        };
    }
    
    componentDidMount() {
        if (this.props.preventReload === 0 || this.props.preventReload === '0') {
            const params = {
                width: this.props.selectedRoom.width * 1000,
                length: this.props.selectedRoom.length * 1000,
                lightPosition: this.props.selectedRoom.lightPosition * 1000
            };

            this.props.autolayoutRoom(params);
        }
        else {
            this.setState({
                orientation: this.props.selectedRoomLayout.orientation,
                lineCount: this.props.selectedRoomLayout.lineCount,
                lineLuminairesCount: this.props.selectedRoomLayout.lineLuminairesCount,
                error: false
            });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.roomLayouts !== this.props.roomLayouts) {
            if (this.props.roomLayouts.horizontal) {
                this.setState({
                    orientation: "horizontal",
                    lineCount: this.props.roomLayouts.horizontal.lineCount,
                    lineLuminairesCount: this.props.roomLayouts.horizontal.lineLuminairesCount,
                    error: false
                });
            } else if (this.props.roomLayouts.vertical) {
                this.setState({
                    orientation: "vertical",
                    lineCount: this.props.roomLayouts.vertical.lineCount,
                    lineLuminairesCount: this.props.roomLayouts.vertical.lineLuminairesCount,
                    error: false
                });
            } else {
                this.setState({...this.state, error: true});
            }

        }
    }
    
    handleRoomRotation = () => {
        if (this.state.orientation === "horizontal" && this.props.roomLayouts.vertical) {
            this.setState({
                ...this.state,
                orientation: 'vertical',
                lineCount: this.props.roomLayouts.vertical.lineCount,
                lineLuminairesCount: this.props.roomLayouts.vertical.lineLuminairesCount,
            });
        }
        else if (this.state.orientation === "vertical" && this.props.roomLayouts.horizontal) {
            this.setState({
                ...this.state,
                orientation: 'horizontal',
                lineCount: this.props.roomLayouts.horizontal.lineCount,
                lineLuminairesCount: this.props.roomLayouts.horizontal.lineLuminairesCount,
            });
        }
    }

    allowRoomRotation = () => {
        if (this.state.orientation === "horizontal" && this.props.roomLayouts.vertical) {
            return true;
        }
        else if (this.state.orientation === "vertical" && this.props.roomLayouts.horizontal) {
            return true;
        }
        return false;
    }

    handleIncreaseLineCount = () => {
        this.setState({...this.state, lineCount: this.state.lineCount+1});
    }

    handleDecreaseLineCount = () => {
        this.setState({...this.state, lineCount: Math.max(this.state.lineCount-1, 1)});
    }

    handleValueChange = param => value => {
        this.props.updateSelectedRoom({key: param, value: value});
    }

    handleCommitAllChanges = () => {
        this.props.updateSelectedRoom({key: 'userCommitted', value: true});
        this.props.updateRoomLayout({
            orientation: this.state.orientation,
            lineCount: this.state.lineCount,
            lineLuminairesCount: this.state.lineLuminairesCount
        });
        navigate('/edit');
    }
    
    render() {    
        const {selectedRoom, isLoading} = this.props;
        const selectedRoomLayout = this.state;

        let renderedLines = [];
        
        if (this.state.lineCount) {
            for (var i = 0; i < this.state.lineCount; i++) {
                renderedLines.push(
                    <div key={i} className={mergeClassNames(styles.roomPreviewLine, this.state.orientation === "vertical" ? styles.roomPreviewLineVertical : styles.roomPreviewLineHorizontal)} />
                )
            }
        }

        const screenWidth = 400;
        const maxPreviewWidth = screenWidth - 30;
        const maxPreviewHeight = screenWidth - 100;

        const roomWidth = selectedRoom.length;
        const roomLength = selectedRoom.width;
        const ratio = roomWidth / roomLength;

        let previewWidth = 0;
        let previewHeight = 0;
        if (ratio >= 1) {
            previewWidth = Math.round(maxPreviewHeight / ratio);
            previewHeight = maxPreviewHeight;
        } else {
            previewWidth = maxPreviewWidth;
            previewHeight = Math.round(maxPreviewWidth * ratio);
        }

        let paddingVertical = 10;
        let paddingHorizontal = 10;

        if (this.state.lineCount) {
            if (this.state.orientation === "vertical") {
                paddingHorizontal = previewWidth / this.state.lineCount / 2;
            } else {
                paddingVertical = previewHeight / this.state.lineCount / 2;
            }
        }

        return (
            <Screen backFunction={() => navigate('/edit/room/light')}>
                <Heading>{i18n.t('roomPreview')}</Heading>
                
                <div className={appStyles.appMain}>
                    {isLoading &&
                        <div style={{marginTop: 40}}>
                            <Loading />
                        </div>
                    }

                    {!isLoading && this.state.error &&
                        <div>
                            <div>{i18n.t('roomLayoutError')}</div>
                        </div>
                    }

                    {!isLoading && !this.state.error &&
                        <div>

                            <div className={styles.notice}>{i18n.t('roomPreviewInfo')}</div>

                            <div className={styles.roomPreviewContainer}>
                                <div 
                                    className={mergeClassNames(styles.roomPreview, this.state.orientation === "vertical" ? styles.roomPreviewVertical : styles.roomPreviewHorizontal)}
                                    style={{paddingTop: paddingVertical, paddingBottom: paddingVertical, paddingLeft: paddingHorizontal, paddingRight: paddingHorizontal, width: previewWidth, height: previewHeight}}
                                    >
                                    {renderedLines}
                                </div>
                            </div>

                            <div className={styles.roomPreviewControls}>

                                {this.allowRoomRotation() &&
                                    <button onClick={this.handleRoomRotation} className={styles.rotateButton}>
                                        <div>
                                            <FontAwesomeIcon icon={faSync} />
                                        </div>
                                        <div>{i18n.t('turn')}</div>
                                    </button>
                                }

                                <div className={styles.lineControl}>

                                    <button onClick={this.handleDecreaseLineCount} className={[styles.lineControlButton]}>
                                        <FontAwesomeIcon icon={faMinusCircle} />
                                    </button>

                                    <div className={styles.lineControlLabel}>{selectedRoomLayout.lineCount}</div>

                                    <button onClick={this.handleIncreaseLineCount} className={[styles.lineControlButton]}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </button>

                                </div>

                            </div>

                        </div>
                    }
                    
                    <ButtonSecondary title={i18n.t('done')} onClick={this.handleCommitAllChanges} disabled={selectedRoomLayout.error} />
                </div>
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomLayoutEdit);
