import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';
import NumberFormat from 'react-number-format';

import styles from './ConfigurationArticleList.module.css';
import appStyles from '../App.module.css';

import Heading from '../../components/Heading';
import ListItem from '../../components/ListItem';
import Screen from '../../components/Screen';

import * as ConfigurationService from '../../services/ConfigurationService';

const mapStateToProps = (state) => {
    return {
        result: state.currentConfiguration.result,
        currentConfiguration: state.currentConfiguration,        
        meta: state.currentConfiguration.meta,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

class ConfigurationArticleList extends Component {

    static getProductTypeStringByType(type) {
        const types = {
            strip: i18n.t('productTypeStrip'),
            endcap: i18n.t('productTypeEndcap'),
            pendulum: i18n.t('productTypePendulum'),
            supply: i18n.t('productTypeSupply'),
            supplyInline: i18n.t('productTypeSupplyInline'),
            connector: i18n.t('productTypeConnector'),
            mount: i18n.t('productTypeMount'),
        };

        return types[type];
    }
    
    render() {
        const {result, currentConfiguration, meta} = this.props;
        const lightDistribution = result?.data?.products ? ConfigurationService.getLightDistribution(result.data.products) : false;
        const radiationAngle = result?.data?.products ? ConfigurationService.getRadiationAngle(result.data.products) : false;
        const luminousFluxAdjusted = result?.data?.products ? ConfigurationService.getLuminousFluxAdjusted(result.data.products) : false;
        
        return (
            <Screen backFunction={() => navigate('/result')}>
                <Heading>{i18n.t('articleList')}</Heading>
                
                <div className={appStyles.appMain}>
                    <div className={styles.text}>
                        <strong>{i18n.t('configurationNumber')}</strong> {meta.configurationNumber}
                    </div>

                    <div className={styles.text}>
                        {i18n.t('articleListDescription.line')} <NumberFormat value={(result.meta.lineCount * result.meta.lineLength / 1000)} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} /> {i18n.t('articleListDescription.linealMeters')}, {i18n.t('articleListDescription.housing')} {currentConfiguration.selectedFilters.color}, {i18n.t('articleListDescription.dimming')} {currentConfiguration.selectedFilters.dimmable}, {i18n.t('articleListDescription.with')} {i18n.t('articleListDescription.lightDistribution.'+lightDistribution)}, {radiationAngle && i18n.t('articleListDescription.optics') + " " + radiationAngle + "° " + i18n.t('articleListDescription.radiationAngle') + ", "}{i18n.t('articleListDescription.lightColor')} {currentConfiguration.selectedFilters.lightColor}, {i18n.t('articleListDescription.with')} <NumberFormat value={luminousFluxAdjusted} decimalScale={0} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} /> lm {i18n.t('articleListDescription.per')} 150cm. 
                    </div>

                    <hr className={styles.divider} />
                    {result && result.data && result.data.products && Object.entries(result.data.products).map(([key, value]) => (
                        <ListItem key={key} value={value.qty + " x " + value.name} name={ConfigurationArticleList.getProductTypeStringByType(value.type)} />
                    ))}
                </div>
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationArticleList);
