import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons'

import i18n from 'i18n-js';

import styles from './Screen.module.css';

class Screen extends Component {
    render() {
        const {
            children,
            backFunction,
            backLabel,
            ...props
        } = this.props;
        
        return (
            <div className={styles.screen} {...props}>
                <div className={styles.screenHeader}>
                    <div className={styles.screenHeaderInner}>
                        {backFunction && (
                            <button onClick={backFunction} className={styles.backButton}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                                {backLabel ? backLabel : i18n.t('back')}
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.screenMain}>
                    {children}
                </div>
            </div>
        )
    }
}

export default Screen;
