import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import TitleImage from "../../assets/linedo-title2.jpg";
import Logo from "../../assets/logo-linedo.svg";

import styles from './Home.module.css';

import * as ActionTypes from "../../actions";
import Button from '../../components/Button';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        configurations: state.configurations,
        currentConfiguration: state.currentConfiguration
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadConfiguration: (params) => dispatch(ActionTypes.loadConfiguration(params)),
        resetConfiguration: () => dispatch(ActionTypes.resetConfiguration())
    };
};

class Home extends Component {

    onNewConfiguration = () => {
        this.props.resetConfiguration();
        navigate('/edit');
    };
    
    /*
    onResumeConfiguration = () => {
        navigate('/edit');
    }
    */
    
    render() {
        return (
            <Screen>
                <img src={TitleImage} alt="LINEDO" className={styles.titleImage} />
                <img src={Logo} alt="LINEDO" className={styles.logo} />
                <Button title={i18n.t('configureNow')} onClick={() => this.onNewConfiguration()} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
