import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import appStyles from '../App.module.css';
import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import FilterSelectionButton from '../../components/FilterSelectionButton';
import Heading from '../../components/Heading';
import Kicker from '../../components/Kicker';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        selectedFilters: state.currentConfiguration.selectedFilters
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedFilters: (params) => dispatch(ActionTypes.updateSelectedFilters(params))
    };
};

class LampLightColorEdit extends Component {

    handleSelection = value => {
        this.props.updateSelectedFilters({key: 'lightColor', value: value});
    }
    
    getLightColorNameFromNumber(number) {
        switch (number) {
            case "830":
                return "3000 K";
            case "840":
                return "4000 K";
            case "850":
                return "5000 K";
            default:
                return "";
        }
    }

    getLightColorImageFromNumber(number) {
        switch (number) {
            case "830":
                return require('../../assets/filter-lightcolor-830.png');
            case "840":
                return require('../../assets/filter-lightcolor-840.png');
            case "850":
                return require('../../assets/filter-lightcolor-850.png');
            default:
                return "";
        }
    }
    
    render() {
        const {filters} = this.props;
        const lightColors = filters.lightColor;
        const selectedLightColor = this.props.selectedFilters.lightColor;
        
        return (
            <Screen backFunction={() => navigate('/edit/lamp/color')}>
                <Heading>{i18n.t('editLamp')}</Heading>
                
                <div className={appStyles.appMain}>
                    <Kicker>{i18n.t('lightColor')}</Kicker>
                    {lightColors && Object.entries(lightColors).map(lightColor => (
                        <FilterSelectionButton onHandleSelection={this.handleSelection} value={lightColor[0]} image={this.getLightColorImageFromNumber(lightColor[0])} title={this.getLightColorNameFromNumber(lightColor[0])} selected={selectedLightColor && selectedLightColor === lightColor[0]} dimmed={selectedLightColor && selectedLightColor !== lightColor[0]} />
                    ))}
                </div>
                
                <ButtonSecondary title={i18n.t('continue')} onClick={() => navigate('/edit/lamp/dimm')} disabled={!selectedLightColor} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LampLightColorEdit);
