import React, { Component } from 'react';
import mergeClassNames from 'merge-class-names';

import styles from './ButtonSelect.module.css';

class ButtonSelect extends Component {
    render() {
        const {
            title,
            onClick,
            selected,
            ...props
        } = this.props;

        return (
            <button className={mergeClassNames(styles.button, selected && styles.buttonSelected )} onClick={onClick} {...props}>
                {title}
            </button>
        )
    }
}

export default ButtonSelect;
