import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';
import HashSum from "hash-sum";
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons';

import styles from './ConfigurationResult.module.css';

import * as ActionTypes from "../../actions";
import * as ConfigurationService from '../../services/ConfigurationService'

import Button from '../../components/Button';
import ButtonSecondary from '../../components/ButtonSecondary';
import Heading from '../../components/Heading';
import Loading from '../../components/Loading';
import ResultValue from '../../components/ResultValue';
import Kicker from '../../components/Kicker';
import Screen from '../../components/Screen';

import ResultCanvas from '../modules/ResultCanvas';

const mapStateToProps = (state) => {
    return {
        configurationMode: state.currentConfiguration.configurationMode,
        selectedRoom: state.currentConfiguration.selectedRoom,
        selectedLine: state.currentConfiguration.selectedLine,
        selectedFilters: state.currentConfiguration.selectedFilters,
        selectedRoomLayout: state.currentConfiguration.selectedRoomLayout,
        meta: state.currentConfiguration.meta,
        result: state.currentConfiguration.result,
        registration: state.registration,

        isLoading: state.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        performCalculation: (mode, params) => dispatch(ActionTypes.performCalculation(mode, params)),
        saveConfiguration: (params) => dispatch(ActionTypes.saveConfiguration(params)),
        updateConfigurationName: (params) => dispatch(ActionTypes.updateConfigurationName(params)),
    };
};

class ConfigurationResult extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            nameUpdated: this.props.meta.name,
            nameValid: !!this.props.meta.name,
            error: false
        };
    }

    onChangeText = (text) => {
        this.setState({
            ...this.state,
            nameValid: text && text.trim() !== "",
            nameUpdated: text.replace(/(\r\n|\n|\r)/gm, "")
        });
    };
    
    componentDidMount() {

        if (this.props.result === false || HashSum({
            selectedRoom: this.props.selectedRoom,
            selectedFilters: this.props.selectedFilters,
            selectedRoomLayout: this.props.selectedRoomLayout,
            selectedLine: this.props.selectedLine,
            configurationMode: this.props.configurationMode
        }) !== this.props.meta.resultConfigurationHash) {
            this.performCalculation();
        }
    }
    
    performCalculation = () => {
        if (this.props.configurationMode === 'room') {
            this.performRoomCalculation();
        } else if (this.props.configurationMode === 'line') {
            this.performLineCalculation();
        }
    };
    
    performRoomCalculation = () => {
        const params = {
            width: this.props.selectedRoom.width * 1000,
            length: this.props.selectedRoom.length * 1000,
            height: this.props.selectedRoom.height * 1000,
            maintenance: this.props.selectedFilters.maintenance,
            workplaneHeight: this.props.selectedFilters.workplaneHeight,
            lightPosition: this.props.selectedRoom.lightPosition * 1000,
            pendulumType: this.props.selectedRoom.pendulumType,
            desiredLux: this.props.selectedRoom.desiredLux,

            orientation: this.props.selectedRoomLayout.orientation,
            lineCount: this.props.selectedRoomLayout.lineCount,
            lineLuminairesCount: this.props.selectedRoomLayout.lineLuminairesCount,

            dimmable: this.props.selectedFilters.dimmable,
            lightColor: this.props.selectedFilters.lightColor,
            color: this.props.selectedFilters.color,
            
            uid: this.props.registration.uid,
            source: 'WEB'
        };

        if (this.props.meta.guid !== false && this.props.meta.configurationNumber !== false && this.props.meta.pin !== false) {
            params.guid = this.props.meta.guid;
            params.configurationNumber = this.props.meta.configurationNumber;
            params.pin = this.props.meta.pin;
        }

        this.props.performCalculation('room', params);
    }
    
    performLineCalculation = () => {
        const params = {
            length: this.props.selectedLine.length * 1000,
            lineCount: this.props.selectedLine.lineCount,
            pendulumType: this.props.selectedLine.pendulumType,
            pendulumForceArticle: this.props.selectedLine.pendulumForceArticle,

            dimmable: this.props.selectedFilters.dimmable,
            lightColor: this.props.selectedFilters.lightColor,
            color: this.props.selectedFilters.color,
            radiationAngle: this.props.selectedFilters.radiationAngle,
            powerLevel: this.props.selectedFilters.powerLevel,
            lightDistributionPercentage: this.props.selectedFilters.lightDistributionPercentage,
            
            uid: this.props.registration.uid,
            source: 'WEB'
        };

        if (this.props.meta.guid !== false && this.props.meta.configurationNumber !== false && this.props.meta.pin !== false) {
            params.guid = this.props.meta.guid;
            params.configurationNumber = this.props.meta.configurationNumber;
            params.pin = this.props.meta.pin;
        }

        this.props.performCalculation('line', params);
    }

    handleSendConfiguration = () => {
        const {registration} = this.props;

        if (registration && registration.givenName && registration.familyName) {
            let mailBody = '';

            if (registration.givenName || registration.familyName) {
                mailBody += registration.givenName + ' ' + registration.familyName + '\r\n';
            }
            if (registration.telephone) {
                mailBody += registration.telephone + '\r\n';
            }
            if (registration.emailAddress) {
                mailBody += registration.emailAddress + '\r\n';
            }
            if (registration.company) {
                mailBody += registration.company + '\r\n';
            }

            if (this.props.configurationMode === 'line') {
                mailBody += '\r\n' + i18n.t('mailRequestBodyLineMode', {
                    // configurationName: this.props.meta.name,
                    configurationNumber: this.props.meta.configurationNumber
                });
            } else {
                mailBody += '\r\n' + i18n.t('mailRequestBody', {
                    // configurationName: this.props.meta.name,
                    dimensions: this.props.selectedRoom.length + 'm x ' + this.props.selectedRoom.width + 'm x ' + this.props.selectedRoom.height + 'm',
                    lightPosition: this.props.selectedRoom.lightPosition + 'm',
                    configurationNumber: this.props.meta.configurationNumber
                });
            }
            
            

            this.props.result && this.props.result.data && this.props.result.data.products && Object.entries(this.props.result.data.products).map(([key, value]) => (
                mailBody += value.qty + ' x ' + value.name + '\r\n'
            ))

            mailBody += '\r\n' + i18n.t('mailRequestFooter');
            
            const link = 'mailto:?subject=' + encodeURIComponent(i18n.t('mailRequestSubject') + ' ' + this.props.meta.configurationNumber) + '&body=' + encodeURIComponent(mailBody);
            // window.location = link;
            window.open(link, '_blank').focus();
        } else {
            navigate('/registration');
        }
    };

    
    render() {
        const {selectedRoom, selectedRoomLayout, configurationMode, result, meta, isLoading} = this.props;
        const lightDistribution = result?.data?.products ? ConfigurationService.getLightDistribution(result.data.products) : false;
        const totalLuminousFlux = result?.data?.products ? ConfigurationService.getTotalLuminousFlux(result.data.products) : false;

        const luxResultDifferenceTooBig = (result && result.meta && result.meta.lux ? Math.abs(selectedRoom.desiredLux - result.meta.lux) > 100 : false);
        const error = ! result;
        
        return (
            <Screen backFunction={() => navigate('/edit')}>
                {isLoading ?
                    <div style={{flex: 1, justifyContent: "center"}}>
                        <Loading />
                        <div style={{flexDirection: 'row', justifyContent: 'center', marginTop: 20, opacity: .75}}>{i18n.t('calculating')}</div>
                    </div> :

                    <div>

                        {error ? (
                            <div style={{marginHorizontal: 16, marginVertical: 16}}>
                                <Heading>{i18n.t('error')}</Heading>
                                <div>
                                    {i18n.t('errorLuxNotPossible', {desiredLux: selectedRoom.desiredLux})}
                                    {selectedRoomLayout && selectedRoomLayout.lineCount > 1 && " " + i18n.t('errorCorrectLines', {desiredLux: selectedRoom.desiredLux})}
                                </div>
                                {
                                    selectedRoomLayout && selectedRoomLayout.lineCount > 1 &&
                                    <button onClick={() => navigate('/edit/room/layout/1')}
                                        style={{flexDirection: 'row', alignItems: 'center', marginTop: 8}}>
                                            <div>{i18n.t('correctLines')}</div>
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                    </button>
                                }
                            </div>
                        ) : (

                            <div>

                                {!isLoading && (

                                    <div style={{flex: 1}}>

                                        <div className={styles.headingContainer}>
                                            <Heading>{i18n.t('configuration')}</Heading>
                                        </div>

                                        {result &&
                                        <div>

                                            {luxResultDifferenceTooBig && (
                                                <div style={{marginHorizontal: 10, marginVertical: 10}}>
                                                    <div>
                                                        {i18n.t('errorLuxDifferenceToBig', {desiredLux: selectedRoom.desiredLux})}
                                                        <div className={styles.alert}>
                                                            {result && result.meta && result.meta.lineLength > 1 &&" " + i18n.t('errorCorrectLines', {desiredLux: selectedRoom.desiredLux})}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {configurationMode === 'room' && (
                                                <div style={{marginBottom: 28}}>
                                                    <Kicker>{i18n.t('roomPreview')}</Kicker>
                                                    <ResultCanvas />
                                                </div>
                                            )}
                                            
                                            <Kicker>{i18n.t('configuration')}</Kicker>
                                            <div className={styles.resultContainer}>
                                                <hr className={styles.divider} />

                                                <ResultValue name={i18n.t('lineCount')} value={result.meta.lineCount}/>
                                                
                                                {configurationMode === 'room' && (
                                                    <ResultValue name={i18n.t('roomDimensions')} value={selectedRoom.length + "m x " + selectedRoom.width + "m x " + selectedRoom.height + "m"}/>
                                                )}
                                                <ResultValue name={i18n.t('configurationNumber')} value={meta.configurationNumber}/>
                                            </div>

                                            <Kicker>{i18n.t('calculation')}</Kicker>
                                            <div className={styles.resultContainer}>
                                                <hr className={styles.divider} />
                                                <ResultValue name={i18n.t('lineLuminairesCount')} value={result.meta.lineLuminairesCount}/>
                                                {lightDistribution && <ResultValue name={i18n.t('lightDistributionPercentage')} value={i18n.t('articleListDescription.lightDistribution.' + lightDistribution)}/>}
                                                <ResultValue name={i18n.t('lineLength')}
                                                             value={<NumberFormat value={(result.meta.lineLength / 1000)} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} suffix={" m"} />}
                                                />
                                                
                                                {configurationMode === 'room' && (
                                                    <ResultValue name={i18n.t('lightPower')} value={result.meta.lux + " lux"}/>
                                                )}

                                                <ResultValue name={i18n.t('totalLumen')} value={totalLuminousFlux + " lm"}/>

                                                
                                                <ResultValue name={i18n.t('price') + "*"}
                                                             value={<NumberFormat value={result.meta.price} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} suffix={" EUR"}  />}
                                                     />
                                                <div className={styles.notice}>(*) {i18n.t('priceInfo')}</div>
                                            </div>

                                            <ButtonSecondary title={i18n.t('showArticleList')} onClick={() => navigate("/result/articlelist")} />
                                        </div>
                                        }

                                    </div>
                                )}
                            </div>

                        )}
                    </div>
                }

                { !isLoading && result && (
                    <div style={{flexDirection: "row", marginTop: 12}}>
                        <Button title={i18n.t('requestConfiguration')} onClick={() => this.handleSendConfiguration()} />
                    </div>
                )}
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationResult);
