import React, { Component } from 'react';

import styles from './Heading.module.css';

class Heading extends Component {
    render() {
        const {
            children,
            ...props
        } = this.props;

        return (
            <h2 className={styles.heading} {...props}>
                {children}
            </h2>
        )
    }
}

export default Heading;
