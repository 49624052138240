import * as ConfigurationService from '../services/ConfigurationService'

export const SAVE_CONFIGURATION_REQUEST = 'SAVE_CONFIGURATION_REQUEST';
export const SAVE_CONFIGURATION_SUCCESS = 'SAVE_CONFIGURATION_SUCCESS';
export const SAVE_CONFIGURATION_FAILURE = 'SAVE_CONFIGURATION_FAILURE';


export function saveConfigurationRequest(payload) {
    return {
        type: SAVE_CONFIGURATION_REQUEST,
        payload
    }
}



export function saveConfigurationSuccess(payload) {
    return {
        type: SAVE_CONFIGURATION_SUCCESS,
        payload
    }
}

export function saveConfigurationFailure(payload) {
    return {
        type: SAVE_CONFIGURATION_FAILURE,
        payload
    }
}

export function saveConfiguration(params) {
    return (dispatch) => {
        dispatch(saveConfigurationRequest(params));

        ConfigurationService.saveConfiguration(params)
            .then(response => {
                dispatch(saveConfigurationSuccess(response));
            })
            .catch(error => {
                dispatch(saveConfigurationFailure(error));
            });
    }
}
