import * as ConfigurationService from '../services/ConfigurationService'

export const PERFORM_REGISTRATION_REQUEST = 'PERFORM_REGISTRATION_REQUEST';
export const PERFORM_REGISTRATION_SUCCESS = 'PERFORM_REGISTRATION_SUCCESS';
export const PERFORM_REGISTRATION_FAILURE = 'PERFORM_REGISTRATION_FAILURE';


export function performRegistrationRequest(payload) {
    return {
        type: PERFORM_REGISTRATION_REQUEST,
        payload
    }
}

export function performRegistrationSuccess(payload) {
    return {
        type: PERFORM_REGISTRATION_SUCCESS,
        payload
    }
}

export function performRegistrationFailure(payload) {
    return {
        type: PERFORM_REGISTRATION_FAILURE,
        payload
    }
}

export function performRegistration(params) {
    return (dispatch) => {
        dispatch(performRegistrationRequest(params));

        ConfigurationService.performRegistration(params)
            .then(response => {
                dispatch(performRegistrationSuccess(params));
            })
            .catch(error => {
                dispatch(performRegistrationFailure(error));
            });
    }
}
