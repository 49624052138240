import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import appStyles from '../App.module.css';
import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import ButtonSelect from '../../components/ButtonSelect';
import ButtonOption from '../../components/ButtonOption';
import Heading from '../../components/Heading';
import Slider from '../../components/Slider';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        selectedLine: state.currentConfiguration.selectedLine
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedLine: (params) => dispatch(ActionTypes.updateSelectedLine(params))
    };
};

class LineEdit extends Component {

    handleValueChange = param => value => {
        this.props.updateSelectedLine({key: param, value: value});
    }
    
    handleValueSelect = (param, value) => event => {
        this.props.updateSelectedLine({key: param, value: value});
        if (param === 'pendulumType') {
            this.props.updateSelectedLine({key: 'pendulumForceArticle', value: false});
        }
        this.setState({...this.state});
    }
    
    handleCommitAllChanges = () => {
        this.props.updateSelectedLine({key: 'userCommitted', value: true});
        navigate('/edit');
    }

    getArticlesByPendulumType() {
        const {selectedLine} = this.props;

        switch (selectedLine.pendulumType) {
            case 'none':
                return ['982580.010', '982666.000'];
            case 'rope':
                return ['982661.000', '982717.000', '982665.000', '982719.000', '982663.000'];
            case 'chain':
                return ['982662.000', '982718.000', '982664.000'];
            default:
                return false; 
        }
    }
    
    render() {
        const {selectedLine} = this.props;
            
        return (
            <Screen backFunction={() => navigate('/edit')}>
                <Heading>{i18n.t('editLine')}</Heading>
                
                <div className={appStyles.appMain}>
                    
                    <Slider
                        label={i18n.t('length')}
                        minimumValue={1}
                        maximumValue={120}
                        unit={"m"}
                        onValueChange={this.handleValueChange('length')}
                        value={selectedLine.length}
                    />

                    <Slider
                        label={i18n.t('lineCount')}
                        minimumValue={1}
                        maximumValue={20}
                        unit={""}
                        step={10}
                        onValueChange={this.handleValueChange('lineCount')}
                        value={selectedLine.lineCount}
                    />
                    
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                        <div>
                            <div>{i18n.t('pendulumType')}</div>
                        </div>
                        <div>
                            <ButtonSelect selected={selectedLine.pendulumType === 'none'} title={i18n.t('pendulumTypes.none')} onClick={this.handleValueSelect('pendulumType', 'none')} />
                            <ButtonSelect selected={selectedLine.pendulumType === 'rope'} title={i18n.t('pendulumTypes.rope')} onClick={this.handleValueSelect('pendulumType', 'rope')} />
                            <ButtonSelect selected={selectedLine.pendulumType === 'chain'} title={i18n.t('pendulumTypes.chain')} onClick={this.handleValueSelect('pendulumType', 'chain')}  />
                        </div>
                    </div>

                    {this.getArticlesByPendulumType() && (
                        <div style={{width: '50%', marginLeft: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'right', marginBottom: '20px'}}>
                            {this.getArticlesByPendulumType().map((value) => (
                                <ButtonOption selected={selectedLine.pendulumForceArticle === value} title={i18n.t('pendulumForceArticles.' + value.replace('.', ''))} onClick={this.handleValueSelect('pendulumForceArticle', value)} />
                            ))}
                        </div>
                    )}
                    
                </div>
                
                <ButtonSecondary title={i18n.t('continue')} onClick={this.handleCommitAllChanges} disabled={!selectedLine.pendulumForceArticle} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineEdit);
