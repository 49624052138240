import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';
import NumberFormat from 'react-number-format';

import appStyles from '../App.module.css';
import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import ButtonSelect from '../../components/ButtonSelect';
import FilterSelectionButton from '../../components/FilterSelectionButton';
import Heading from '../../components/Heading';
import Kicker from '../../components/Kicker';
import Screen from '../../components/Screen';
import Slider from '../../components/Slider';

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        selectedFilters: state.currentConfiguration.selectedFilters,
        configurationMode: state.currentConfiguration.configurationMode
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedFilters: (params) => dispatch(ActionTypes.updateSelectedFilters(params))
    };
};

class LampDimmEdit extends Component {
    
    handleSelection = value => {
        this.props.updateSelectedFilters({key: 'dimmable', value: value});
    }
    
    handleSelectionPowerLevel = value => {
        this.props.updateSelectedFilters({key: 'powerLevel', value: value});
    }
    
    handleSelectionRadiationAngle = value => event => {
        this.props.updateSelectedFilters({key: 'radiationAngle', value: value});
    }
    
    handleSelectionLightDistributionPercentage = value => event => {
        this.props.updateSelectedFilters({key: 'lightDistributionPercentage', value: value});
    }

    handleCommitAllChanges = () => {
        this.props.updateSelectedFilters({key: 'userCommitted', value: true});
        navigate('/edit');
    }

    getLumensByPowerLevel() {
        const {powerLevel} = this.props.selectedFilters;
        
        const lumens = [1700, 2450, 3400, 4100, 5200];
        let lumen = lumens[powerLevel];

        // Adjust lumen for 150cm length
        lumen = (lumen / 58) * 150;
        
        return lumen;
    }

    getLumensByPowerLevelPerUnit() {
        const {powerLevel} = this.props.selectedFilters;
        
        const lumens = [1700, 2450, 3400, 4100, 5200];
        let lumen = lumens[powerLevel];
        
        return lumen;
    }

    getWeightedLumensByPowerLevel() {
        const {powerLevel, lightDistributionPercentage} = this.props.selectedFilters;
        
        const lumens = [1700, 2450, 3400, 4100, 5200];
        let lumen = lumens[powerLevel];

        // Adjust lumen for light distribution percentage
        lumen = (lumen / 100) * lightDistributionPercentage;

        // Adjust lumen for 150cm length
        lumen = (lumen / 58) * 150;

        return lumen;
    }

    render() {
        const {filters, configurationMode} = this.props;
        
        const dimmables = filters.dimmable;
        // const powerLevels = filters.powerLevel;
        const radiationAngles = filters.radiationAngle;
        const lightDistributionPercentages = filters.lightDistributionPercentage;
        
        const selectedDimmable = this.props.selectedFilters.dimmable;
        const selectedPowerLevel = this.props.selectedFilters.powerLevel;
        const selectedRadiationAngle = this.props.selectedFilters.radiationAngle;
        const selectedLightDistributionPercentage = this.props.selectedFilters.lightDistributionPercentage;

        return (
            <Screen backFunction={() => navigate('/edit/lamp/lightcolor')}>
                <Heading>{i18n.t('editLamp')}</Heading>
                
                <div className={appStyles.appMain}>
                    <Kicker>{i18n.t('circuitType')}</Kicker>
                    {dimmables && Object.entries(dimmables).map(dimmable => (
                        <FilterSelectionButton onHandleSelection={this.handleSelection} value={dimmable[0]} title={dimmable[0]} selected={selectedDimmable && selectedDimmable === dimmable[0]} dimmed={selectedDimmable && selectedDimmable !== dimmable[0]} />
                    ))}
                    
                    {configurationMode === 'line' && (
                        <>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                                <div>
                                    <div>{i18n.t('radiationAngle')}</div>
                                </div>
                                <div>
                                    {radiationAngles && Object.entries(radiationAngles).map(radiationAngle => (
                                        <ButtonSelect selected={selectedRadiationAngle && selectedRadiationAngle === radiationAngle[0]} title={radiationAngle[0] + '°'} onClick={this.handleSelectionRadiationAngle(radiationAngle[0])} />
                                    ))}
                                </div>
                            </div>
                        
                            <div style={{marginTop: '40px', position: 'relative'}}>
                                <Slider
                                    label={i18n.t('powerLevel')}
                                    minimumValue={0}
                                    maximumValue={4}
                                    unit={""}
                                    step={10}
                                    onValueChange={this.handleSelectionPowerLevel}
                                    value={selectedPowerLevel}
                                    hideIncreaseDecreaseButtons={true}
                                />
                                <div style={{textAlign: 'right', fontWeight: 'bold', position: 'absolute', top: '0', right: '0'}}>
                                   <NumberFormat value={this.getLumensByPowerLevel()} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={' lm'} />
                                </div>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '0'}}>
                                <div>
                                    <div>{i18n.t('powerLevelPerUnit')}</div>
                                </div>
                                <div style={{textAlign: 'right', fontWeight: 'bold'}}>
                                   <NumberFormat value={this.getLumensByPowerLevelPerUnit()} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={' lm'} />
                                </div>
                            </div>
                            
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px'}}>
                                <div>
                                    <div>{i18n.t('lightDistributionPercentage')}</div>
                                </div>
                                <div>
                                    {lightDistributionPercentages && Object.entries(lightDistributionPercentages).map(lightDistributionPercentage => (
                                        <ButtonSelect selected={selectedLightDistributionPercentage && selectedLightDistributionPercentage === lightDistributionPercentage[0]} title={i18n.t('lightDistributionPercentages.' + lightDistributionPercentage[0])} onClick={this.handleSelectionLightDistributionPercentage(lightDistributionPercentage[0])} />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                
                <ButtonSecondary title={i18n.t('done')} onClick={this.handleCommitAllChanges} disabled={!selectedDimmable}/>
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LampDimmEdit);
