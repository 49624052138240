import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import appStyles from '../App.module.css';
import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import FilterSelectionButton from '../../components/FilterSelectionButton';
import Heading from '../../components/Heading';
import Kicker from '../../components/Kicker';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        selectedFilters: state.currentConfiguration.selectedFilters
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedFilters: (params) => dispatch(ActionTypes.updateSelectedFilters(params))
    };
};


class LampColorEdit extends Component {
    
    handleSelection = value => {
        this.props.updateSelectedFilters({key: 'color', value: value});
    }

    getLabelFromColorName(name) {
        if (name.includes("weiß")) {
            return i18n.t('colors.white');
        } else if (name.includes("schwarz")) {
            return i18n.t('colors.black');
        }

        return i18n.t('colors.aluminium');
    }

    getImageFromColorName(name) {
        if (name.includes("weiß")) {
            return require('../../assets/filter-color-white.png');
        } else if (name.includes("schwarz")) {
            return require('../../assets/filter-color-black.png');
        }

        return require('../../assets/filter-color-aluminium.png');
    }

    render() {
        const {filters} = this.props;
        const colors = filters.color;
        const selectedColor = this.props.selectedFilters.color;
        
        return (
            <Screen backFunction={() => navigate('/edit')}>
                <Heading>{i18n.t('editLamp')}</Heading>
                
                <div className={appStyles.appMain}>
                    <Kicker>{i18n.t('casingColor')}</Kicker>
                    {colors && Object.entries(colors).map(color => (
                        <FilterSelectionButton image={this.getImageFromColorName(color[0])} onHandleSelection={this.handleSelection} value={color[0]} title={this.getLabelFromColorName(color[0])} selected={selectedColor && selectedColor === color[0]} dimmed={selectedColor && selectedColor !== color[0]} />
                    ))}
                </div>
                
                <ButtonSecondary title={i18n.t('continue')} onClick={() => navigate('/edit/lamp/lightcolor')} disabled={!selectedColor} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LampColorEdit);
