import React, { Component } from 'react';

import styles from './ListItem.module.css';

class ListItem extends Component {
    render() {
        const {
            name,
            value,
            ...props
        } = this.props;

        return (
            <div className={styles.item} {...props}>
                <div className={styles.value}>{value}</div>
                <div className={styles.name}>{name}</div>
            </div>
        )
    }
}

export default ListItem;
