import React, { Component } from 'react';
import ReactSlider from 'react-slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import styles from './Slider.module.css';

class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentValue: props.value
        };
    }

    handleValueChange = value => {
        const currentValue = Math.round(value) / 10;

        this.setState({
            currentValue: currentValue
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(currentValue);
        }
    }

    handleIncreaseValue = () => {
        const {currentValue} = this.state;
        const step = this.props.step ? this.props.step : 1;
        const {maximumValue} = this.props;

        const newValue = Math.min(Math.round(currentValue * 10 + step) / 10, maximumValue);

        this.setState({
            currentValue: newValue
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(newValue);
        }
    }

    handleDecreaseValue = () => {
        const {currentValue} = this.state;
        const step = this.props.step ? this.props.step : 1;
        const {minimumValue} = this.props;

        const newValue = Math.max(Math.round(currentValue * 10 - step) / 10, minimumValue);

        this.setState({
            currentValue: newValue
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(newValue);
        }
    }
    
    render() {

        const {
            label,
            minimumValue,
            maximumValue,
            unit,
            step,
            value,
            hideIncreaseDecreaseButtons
        } = this.props;

        const {currentValue} = this.state;

        return (
            <div>
                <div className={styles.labelContainer}>
                    <div className={styles.labelElement}>{label}</div>

                    { ! hideIncreaseDecreaseButtons && (
                        <div className={styles.valueContainer}>
                            <button onClick={this.handleDecreaseValue} className={styles.controlButton}>
                                <FontAwesomeIcon icon={faMinusCircle} />
                            </button>
                            <div className={styles.selectedValue}>{currentValue} {unit}</div>
                            <button onClick={this.handleIncreaseValue} className={styles.controlButton}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </button>
                        </div>
                    )}
                </div>

                <ReactSlider
                    className={styles.slider}
                    thumbClassName={styles.sliderThumb}
                    trackClassName={styles.sliderTrack}
                    min={minimumValue * 10}
                    max={maximumValue * 10}
                    value={value * 10}
                    step={step}
                    onChange={this.handleValueChange}
                />
            </div>
        )
    }
}

export default Slider;
