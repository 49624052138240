import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import styles from './RoomLightEdit.module.css';
import appStyles from '../App.module.css';
import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import ButtonSelect from '../../components/ButtonSelect';
import Heading from '../../components/Heading';
import Slider from '../../components/Slider';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        selectedRoom: state.currentConfiguration.selectedRoom
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedRoom: (params) => dispatch(ActionTypes.updateSelectedRoom(params))
    };
};

class RoomLightEdit extends Component {
    
    handleValueChange = param => value => {
        this.props.updateSelectedRoom({key: param, value: value});
    }

    handleValueSelect = (param, value) => event => {
        this.props.updateSelectedRoom({key: param, value: value});
        this.setState({...this.state});
    }
    
    render() {
        const {selectedRoom} = this.props;
        
        return (
            <Screen backFunction={() => navigate('/edit/room/dimensions')}>
                <Heading>{i18n.t('editRoom')}</Heading>
                
                <div className={appStyles.appMain}>
                    
                    <Slider
                        label={i18n.t('lightPosition')}
                        minimumValue={Math.max(selectedRoom.height - 5, 1)}
                        maximumValue={selectedRoom.height}
                        unit={"m"}
                        onValueChange={this.handleValueChange('lightPosition')}
                        value={selectedRoom.lightPosition}
                    />

                    {selectedRoom.lightPosition < selectedRoom.height && (
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                            <div>
                                <div>{i18n.t('pendulumType')}</div>
                            </div>
                            <div>
                                <ButtonSelect selected={selectedRoom.pendulumType === 'rope'} title={i18n.t('pendulumTypes.rope')} onClick={this.handleValueSelect('pendulumType', 'rope')} />
                                <ButtonSelect selected={selectedRoom.pendulumType === 'chain'} title={i18n.t('pendulumTypes.chain')} onClick={this.handleValueSelect('pendulumType', 'chain')}  />
                            </div>
                        </div>
                    )}

                    <Slider
                        label={i18n.t('desiredLux')}
                        minimumValue={100}
                        maximumValue={900}
                        unit={"lux"}
                        step={500}
                        onValueChange={this.handleValueChange('desiredLux')}
                        value={selectedRoom.desiredLux}
                    />
                    
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                        <div>
                            <div>{i18n.t('workplaneHeight')}</div>
                        </div>
                        <div>
                            <ButtonSelect selected={selectedRoom.workplaneHeight === 0} title="0 m" onClick={this.handleValueSelect('workplaneHeight', 0)} />
                            <ButtonSelect selected={selectedRoom.workplaneHeight === 750} title="0.75 m" onClick={this.handleValueSelect('workplaneHeight', 750)}  />
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                        <div>
                            <div>{i18n.t('maintenance')}</div>
                        </div>
                        <div>
                            <ButtonSelect selected={selectedRoom.maintenance === '0,67'} title="0,67" onClick={this.handleValueSelect('maintenance', '0,67')} />
                            <ButtonSelect selected={selectedRoom.maintenance === '0,8'} title="0,8" onClick={this.handleValueSelect('maintenance', '0,8')} />
                        </div>
                    </div>

                    <div className={styles.notice}>{i18n.t('reflectionLevelInfo')}</div>
                </div>
                
                <ButtonSecondary title={i18n.t('continue')} onClick={() => navigate('/edit/room/layout/0')} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomLightEdit);
