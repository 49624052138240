import * as ConfigurationService from '../services/ConfigurationService'

export const NEW_CONFIGURATION_REQUEST = 'NEW_CONFIGURATION_REQUEST';
export const NEW_CONFIGURATION_SUCCESS = 'NEW_CONFIGURATION_SUCCESS';
export const NEW_CONFIGURATION_FAILURE = 'NEW_CONFIGURATION_FAILURE';

export function newConfigurationRequest() {
    return {
        type: NEW_CONFIGURATION_REQUEST
    }
}

export function newConfigurationSuccess(payload) {
    return {
        type: NEW_CONFIGURATION_SUCCESS,
        payload
    }
}

export function newConfigurationFailure(payload) {
    return {
        type: NEW_CONFIGURATION_FAILURE,
        payload
    }
}

export function newConfiguration() {
    return (dispatch) => {
        dispatch(newConfigurationRequest());

        ConfigurationService.newConfiguration()
            .then(response => {
                dispatch(newConfigurationSuccess(response));
            })
            .catch(error => {
                dispatch(newConfigurationFailure(error));
            });
    }
}
