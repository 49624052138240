import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import appStyles from '../App.module.css';
import * as ActionTypes from "../../actions";
import ButtonSecondary from '../../components/ButtonSecondary';
import Heading from '../../components/Heading';
import Slider from '../../components/Slider';
import Screen from '../../components/Screen';

const mapStateToProps = (state) => {
    return {
        selectedRoom: state.currentConfiguration.selectedRoom
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedRoom: (params) => dispatch(ActionTypes.updateSelectedRoom(params))
    };
};

class RoomDimensionsEdit extends Component {

    handleValueChange = param => value => {
        this.props.updateSelectedRoom({key: param, value: value});
    }
    
    render() {
        const {selectedRoom} = this.props;
            
        return (
            <Screen backFunction={() => navigate('/edit')}>
                <Heading>{i18n.t('editRoom')}</Heading>
                
                <div className={appStyles.appMain}>
                    
                    <Slider
                        label={i18n.t('length')}
                        minimumValue={1}
                        maximumValue={100}
                        unit={"m"}
                        onValueChange={this.handleValueChange('length')}
                        value={selectedRoom.length}
                    />

                    <Slider
                        label={i18n.t('width')}
                        minimumValue={1}
                        maximumValue={100}
                        unit={"m"}
                        onValueChange={this.handleValueChange('width')}
                        value={selectedRoom.width}
                    />

                    <Slider
                        label={i18n.t('height')}
                        minimumValue={1}
                        maximumValue={20}
                        unit={"m"}
                        onValueChange={this.handleValueChange('height')}
                        value={selectedRoom.height}
                    />
                    
                </div>
                
                <ButtonSecondary title={i18n.t('continue')} onClick={() => navigate('/edit/room/light')} />
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomDimensionsEdit);
