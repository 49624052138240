import React, { Component } from 'react';
import {connect} from "react-redux";
import { navigate } from "@reach/router";
import i18n from 'i18n-js';

import styles from './Registration.module.css';

import * as ActionTypes from "../../actions";
import Button from '../../components/Button';
import Screen from '../../components/Screen';
import Heading from '../../components/Heading';
import Loading from '../../components/Loading';

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        meta: state.currentConfiguration.meta,
        isLoading: state.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        performRegistration: (params) => dispatch(ActionTypes.performRegistration(params))
    };
};

class Registration extends Component {
    
    categories = {
        A: i18n.t('registration.categoryValue.A'),
        LP: i18n.t('registration.categoryValue.LP'),
        GH: i18n.t('registration.categoryValue.GH'),
        P: i18n.t('registration.categoryValue.P'),
        I: i18n.t('registration.categoryValue.I'),
        L: i18n.t('registration.categoryValue.L'),
        B: i18n.t('registration.categoryValue.B'),
        none: i18n.t('registration.categoryValue.none'),
    };
    
    constructor(props) {
        super(props);

        this.state = {
            givenName: '',
            familyName: '',
            emailAddress: '',
            telephone: '',
            company: '',
            category: false,
            userConsent: false,
            isValid: true,
            isModalVisible: false,
            isNoticeVisible: true
        };
    }
    
    hideNotice() {
        this.setState({ ...this.state, isNoticeVisible: false });
    }
    
    onChangeText = (key, event) => {
        let registration = this.state;
        registration[key] = event.target.value;

        this.setState(registration);
    };
    
    onToggleCheckbox = (key, event) => {
        let registration = this.state;
        registration[key] = ! registration[key];
        
        this.setState(registration);
    }
    
    performRegistration = () => {
        const {givenName, familyName, emailAddress, telephone, company, category, userConsent} = this.state;
        const {meta, registration} = this.props;

        if (givenName && familyName && emailAddress && userConsent) {
            this.props.performRegistration({
                configurationNumber: meta.configurationNumber,
                givenName: givenName,
                familyName: familyName,
                emailAddress: emailAddress,
                telephone: telephone,
                company: company,
                category: (category === 'none' ? '' : category),
                uid: registration.uid
            });
        } else {
            this.setState({...this.state, isValid: false});
        }
    };
    
    render() {
        const {isLoading, registration} = this.props;
        const {givenName, familyName, emailAddress, telephone, company, userConsent, isValid, isNoticeVisible} = this.state;

        if (registration && registration.givenName && registration.familyName) {
            navigate('/result');
        }
        
        return (
            <Screen backFunction={() => navigate('/result')}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <Heading>{i18n.t('registration.heading')}</Heading>
                        
                        {isNoticeVisible ? (
                                <>
                                    <div className={styles.notice}>{i18n.t('registration.notice')}</div>
                                    <Button title={i18n.t('register')} onClick={() => this.hideNotice()}/>
                                </>
                            ) : (
                                <>
                                    <div className={styles.inputGroup}>
                                        <label className={styles.label}>{i18n.t('registration.givenName')}*</label>
                                        <input value={givenName} onChange={(event) => this.onChangeText('givenName', event)}
                                           className={styles.textInput} placeholder={i18n.t('registration.givenNamePlaceholder')} />
                                    </div>

                                    <div className={styles.inputGroup}>
                                        <label className={styles.label}>{i18n.t('registration.familyName')}*</label>
                                        <input value={familyName} onChange={(event) => this.onChangeText('familyName', event)}
                                               className={styles.textInput} placeholder={i18n.t('registration.familyNamePlaceholder')} />
                                    </div>
                                   
                                    <div className={styles.inputGroup}>
                                        <label className={styles.label}>{i18n.t('registration.email')}*</label>
                                        <input value={emailAddress}
                                               onChange={(event) => this.onChangeText('emailAddress', event)}
                                               className={styles.textInput} placeholder={i18n.t('registration.emailPlaceholder')} />
                                    </div>
                                    
                                    <div className={styles.inputGroup}>
                                        <label className={styles.label}>{i18n.t('registration.telephone')}*</label>
                                        <input value={telephone}
                                               onChange={(event) => this.onChangeText('telephone', event)}
                                               className={styles.textInput} placeholder={i18n.t('registration.telephonePlaceholder')} />
                                    </div>

                                    <div className={styles.inputGroup}>
                                        <label className={styles.label}>{i18n.t('registration.company')}*</label>
                                        <input value={company} onChange={(event) => this.onChangeText('company', event)}
                                               className={styles.textInput} placeholder={i18n.t('registration.companyPlaceholder')}/>
                                    </div>
                                        
                                    <div className={styles.inputGroup}>
                                        <label className={styles.label}>{i18n.t('registration.categories')}*</label>
                                        <select className={styles.selectInput} onChange={(event) => this.onChangeText('category', event)}>
                                            <option value=""></option>
                                            {Object.entries(this.categories).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                   
                                    <div className={styles.inputGroup}>
                                        <input className={styles.checkboxInput} type="checkbox" value={userConsent} onChange={(event) => this.onToggleCheckbox('userConsent', event)}  />
                                        <div className={styles.switchLabel}>{i18n.t('registration.dataProtection')}*</div>
                                    </div>

                                    {!isValid && (
                                        <div className={styles.alert}>{i18n.t('registration.requiredFieldsInfo')}</div>
                                    )}
                                    
                                    <div className={styles.buttonWrap}>
                                        <Button title={i18n.t('confirmRegistration')} onClick={() => this.performRegistration()}/>
                                    </div>
                                </>
                            )}
                    </>
                )}
            </Screen>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
