import * as ConfigurationService from '../services/ConfigurationService'

export const FETCH_FILTERS_REQUEST = 'FETCH_FILTERS_REQUEST';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_FAILURE = 'FETCH_FILTERS_FAILURE';

export function fetchFiltersRequest() {
    return {
        type: FETCH_FILTERS_REQUEST
    }
}

export function fetchFiltersSuccess(payload) {
    return {
        type: FETCH_FILTERS_SUCCESS,
        payload
    }
}

export function fetchFiltersFailure(payload) {
    return {
        type: FETCH_FILTERS_FAILURE,
        payload
    }
}

export function fetchFilters() {
    return (dispatch) => {
        dispatch(fetchFiltersRequest());

        ConfigurationService.fetchFilters()
            .then(response => {
                dispatch(fetchFiltersSuccess(response));
            })
            .catch(error => {
                dispatch(fetchFiltersFailure(error));
            });
    }
}
