import React, { Component } from 'react';

import i18n from 'i18n-js';
import * as qs from 'query-string';

import LangEN from '../assets/lang-en';
import LangDE from '../assets/lang-de';

class LocalizedView extends Component {

    componentWillMount() {
        const params = qs.parse(window.location.search);

        i18n.translations = {
            de: LangDE,
            en: LangEN,
        };
        i18n.locale = params.locale ? params.locale : "de";
        i18n.defaultLocale = "de";
        i18n.fallbacks = true;
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );

    }
}

export default LocalizedView;
