import React, {Component} from 'react';
import {connect} from 'react-redux';
import NumberFormat from 'react-number-format';
import mergeClassNames from 'merge-class-names';

import styles from './ResultCanvas.module.css';

import * as ConfigurationService from '../../services/ConfigurationService'

const mapStateToProps = (state) => {
    return {
        selectedRoom: state.currentConfiguration.selectedRoom,
        selectedRoomLayout: state.currentConfiguration.selectedRoomLayout,
        result: state.currentConfiguration.result,
    };
};

class ResultCanvas extends Component {

    render() {
        const {selectedRoom, selectedRoomLayout, result} = this.props;

        const screenWidth = 400;
        const maxPreviewWidth = screenWidth - 80;
        const maxPreviewHeight = screenWidth;

        const roomWidth = selectedRoom.length;
        const roomLength = selectedRoom.width;
        const ratio = roomWidth / roomLength;

        let previewWidth = 0;
        let previewHeight = 0;
        if (ratio >= 1) {
            previewWidth = Math.round(maxPreviewHeight / ratio);
            previewHeight = maxPreviewHeight;
        } else {
            previewWidth = maxPreviewWidth;
            previewHeight = Math.round(maxPreviewWidth * ratio);
        }

        let paddingVertical = 10;
        let paddingHorizontal = 10;

        if (selectedRoomLayout.lineCount) {
            if (selectedRoomLayout.orientation === "vertical") {
                paddingHorizontal = previewWidth / selectedRoomLayout.lineCount / 2;
            } else {
                paddingVertical = previewHeight / selectedRoomLayout.lineCount / 2;
            }
        }

        const singleLine = ConfigurationService.getProductsForSingleLine(result);

        let renderedLineParts = [];
        if (singleLine) {
            singleLine.forEach((line, i) => {

                let renderedLineFragments = [];
                for (let j = 0; j < line.attributes.lightDistribution.length; j++) {
                    renderedLineFragments.push(
                        <div key={j} className={mergeClassNames(styles.lineFragment, line.attributes.lightDistribution.substr(j, 1) === "0" && styles.lineFragmentInactive)} />
                    )
                }

                renderedLineParts.push(
                    <div key={i} className={mergeClassNames(styles.linePart, selectedRoomLayout.orientation === "vertical" ? styles.linePartVertical : styles.linePartHorizontal)} style={{ flex: Math.round(line.attributes.length)}}>{renderedLineFragments}</div>
                )

            })
        }

        let renderedLines = [];
        if (selectedRoomLayout.lineCount) {
            for (var i = 0; i < selectedRoomLayout.lineCount; i++) {
                renderedLines.push(
                    <div key={i} className={mergeClassNames(styles.line, selectedRoomLayout.orientation === "vertical" ? styles.lineVertical : styles.lineHorizontal)}>{renderedLineParts}</div>
                )
            }
        }



        return (
            <div className={styles.container}>
                <div className={styles.roomContainer}>
                    <div className={mergeClassNames(styles.room, selectedRoomLayout.orientation === "vertical" ? styles.roomVertical : styles.roomHorizontal)} style={{paddingTop: paddingVertical, paddingBottom: paddingVertical, paddingLeft: paddingHorizontal, paddingRight: paddingHorizontal, width: previewWidth, height: previewHeight}}>{renderedLines}</div>
                    <div className={styles.dimension}>
                        <div className={styles.dimensionLabel}>
                            <NumberFormat value={selectedRoom.width} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} suffix={" m"} />
                        </div>
                    </div>
                    <div className={styles.dimensionVertical}>
                        <div className={styles.dimensionLabel}>
                            <NumberFormat value={selectedRoom.length} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} suffix={" m"} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ResultCanvas);
