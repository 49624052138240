import React, { Component } from 'react';

import styles from './Kicker.module.css';

class Kicker extends Component {
    render() {
        const {
            children,
            ...props
        } = this.props;

        return (
            <h3 className={styles.kicker} {...props}>
                {children}
            </h3>
        )
    }
}

export default Kicker;
