import axios from 'axios'
import handleError from './handleError'

export const FETCH_FILTERS_URL = 'https://www.rzb.de/index.php?type=2000&query=products';
export const AUTOLAYOUT_ROOM_URL = 'https://www.rzb.de/index.php?type=2000&query=layout';
export const PERFORM_ROOM_CALCULATION_URL = 'https://www.rzb.de/index.php?type=2000&query=calculate';
export const PERFORM_LINE_CALCULATION_URL = 'https://www.rzb.de/index.php?type=2000&query=calculateLine';
export const NEW_CONFIGURATION_URL = 'https://www.rzb.de/index.php?type=2000&query=new';
export const SAVE_CONFIGURATION_URL = 'https://www.rzb.de/index.php?type=2000&query=save';
export const LOAD_CONFIGURATION_URL = 'https://www.rzb.de/index.php?type=2000&query=load';
export const PERFORM_REGISTRATION_URL = 'https://www.rzb.de/index.php?type=2000&query=registration';

export const fetchFilters = () => {
    return new Promise((resolve, reject) => {

        axios.get(FETCH_FILTERS_URL)
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const autolayoutRoom = (params) => {
    return new Promise((resolve, reject) => {

        axios.get(AUTOLAYOUT_ROOM_URL, {params: params})
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const performCalculation = (mode, params) => {
    return new Promise((resolve, reject) => {

        if (mode === 'room') {
            axios.get(PERFORM_ROOM_CALCULATION_URL, {params: params})
                .then(response => { resolve(response) })
                .catch(error => { reject(handleError(error)) });
        } else if (mode === 'line') {
            axios.get(PERFORM_LINE_CALCULATION_URL, {params: params})
                .then(response => { resolve(response) })
                .catch(error => { reject(handleError(error)) });
        } else {
            reject();
        }
    });
};

export const fetchConfigurations = () => {
    return new Promise((resolve, reject) => {

        resolve([
            {
                meta: {
                    name: "Schwimmbadhalle",
                    crdate: "17.12.2019"
                }
            }, {
                meta: {
                    name: "Automesse, Saal 4",
                    crdate: "22.12.2019"
                }
            }
        ]);
    });
};

export const newConfiguration = () => {
    return new Promise((resolve, reject) => {

        axios.get(NEW_CONFIGURATION_URL)
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const saveConfiguration = (params) => {
    return new Promise((resolve, reject) => {

        axios.get(SAVE_CONFIGURATION_URL, {params: params})
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const loadConfiguration = (params) => {
    return new Promise((resolve, reject) => {

        axios.get(LOAD_CONFIGURATION_URL, {params: params})
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const performRegistration = (params) => {
    return new Promise((resolve, reject) => {

        axios.get(PERFORM_REGISTRATION_URL, {params: params})
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};



export const getLightDistribution = (products) => {

    products.sort((a, b) => {
        return a.product.attributes.length - b.product.attributes.length;
    });

    let lightDistribution = false;

    products.forEach((product) => {
        if (product.type === "strip" && product.product.attributes.lightDistribution) {
            lightDistribution = product.product.attributes.lightDistribution;
            return lightDistribution;
        }
    });

    return lightDistribution;

};


export const getRadiationAngle = (products) => {

    let radiationAngle = false;

    products.forEach((product) => {
        if (product.type === "strip" && product.product.attributes.radiationAngle && product.product.attributes.radiationAngle > 0) {
            radiationAngle = product.product.attributes.radiationAngle;
            return false;
        }
    });

    return radiationAngle;

};


export const getLuminousFlux = (products) => {

    let luminousFlux = false;

    products.forEach((product) => {
        if (product.type === "strip" && product.product.attributes.luminousFlux && product.product.attributes.luminousFlux > 0) {
            luminousFlux = product.product.attributes.luminousFlux;
            return false;
        }
    });

    return luminousFlux;

};

export const getTotalLuminousFlux = (products) => {

    let luminousFlux = 0;

    products.forEach((product) => {
        if (product.type === "strip" && product.product.attributes.luminousFlux && product.product.attributes.luminousFlux > 0) {
            luminousFlux += product.product.attributes.luminousFlux * product.qty;
        }
    });

    return luminousFlux;
};


export const getLuminousFluxAdjusted = (products) => {

    products.sort((a, b) => {
        return a.product.attributes.length - b.product.attributes.length;
    });

    let luminousFlux = false;

    products.forEach((product) => {
        if (product.type === "strip" && product.product.attributes.luminousFlux) {
            luminousFlux = product.product.attributes.luminousFlux;
            luminousFlux = (luminousFlux / product.product.attributes.length) * 1500;
            return luminousFlux;
        }
    });

    return luminousFlux;

};


export const getProductsForSingleLine = (result) => {
    let config = [];

    result.data.products.forEach((product) => {
        if (product.type === "strip") {

            for (let i = 0; i < product.qty / result.meta.lineCount; i++) {
                config.push(product.product);
            }

        }
    });

    config.sort((a, b) => {
        return b.attributes.length - a.attributes.length;
    });

    return config;
};

