import React, { Component } from 'react';

import LoadingVideo from "../assets/linedo-loading.mp4";

class Loading extends Component {
    render() {
        return (
            <div style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <video src={LoadingVideo} autoPlay loop style={{ height: '128', width: '128'}} />
            </div>
        )
    }
}

export default Loading;
