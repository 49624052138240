import React, { Component } from 'react';
import mergeClassNames from 'merge-class-names';

import styles from './Button.module.css';

class Button extends Component {
    render() {
        const {
            title,
            onClick,
            disabled,
            ...props
        } = this.props;

        return (
            <button className={mergeClassNames(styles.button, disabled && styles.buttonDisabled )} disabled={disabled} onClick={onClick} {...props}>
                {title}
            </button>
        )
    }
}

export default Button;
