import React, { Component } from 'react';
import mergeClassNames from 'merge-class-names';

import styles from './FilterSelectionButton.module.css';

class FilterSelectionButton extends Component {
    
    handleSelection = () => {
        this.props.onHandleSelection(this.props.value);
    }

    render() {
        const {
            title,
            selected,
            dimmed,
            image,
            ...props
        } = this.props;
        
        return (
            <button className={mergeClassNames(styles.button, dimmed ? styles.buttonDimmed : (selected && styles.buttonSelected) )} onClick={this.handleSelection} {...props}>
                <div className={styles.row}>
                    <div className={mergeClassNames(styles.text, dimmed && styles.textDimmed)}>
                        {title}
                    </div>
                    {image && (
                        <div>
                            <img className={styles.image} src={image.default} alt="" />
                        </div>
                    )}
                </div>
            </button>
        )
    }
}

export default FilterSelectionButton;
